<template>
  <div class="d-flex flex-column justify-content-end">
    <!-- Page Selection start -->
    <div v-if="endPageView == 'pageSelection'">
      <p
        style="
          color: #1f1f28;
          font-family: Poppins;
          font-size: 14.206px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          letter-spacing: 0.568px;
        "
      >
        What type of Result page do you prefer?
      </p>

      <div class="mt-2 row">
        <div class="col-6 ">
          <div
           @click="handleEndPageSelection('thankYou')"
            class="w-100 pageSelectionContainer cursor-pointer"
            :class="endPageSelection == 'thankYou' ? 'selected' : null"
          >
            <div
              class="w-100 d-flex align-items-center justify-content-center"
            >
              <svg
                width="327"
                height="146"
                viewBox="0 0 327 146"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.890686"
                  y="0.498779"
                  width="325.458"
                  height="144.648"
                  rx="5.16599"
                  fill="#F9F9F9"
                />
                <rect
                  x="105.281"
                  y="37.3065"
                  width="116.678"
                  height="16.4721"
                  rx="2.74536"
                  fill="#D4D4D4"
                  fill-opacity="0.5"
                />
                <rect
                  x="43.5101"
                  y="64.7601"
                  width="240.219"
                  height="5.49071"
                  rx="2.74536"
                  fill="#D4D4D4"
                  fill-opacity="0.3"
                />
                <rect
                  x="74.3954"
                  y="75.7416"
                  width="178.448"
                  height="5.49071"
                  rx="2.74536"
                  fill="#D4D4D4"
                  fill-opacity="0.3"
                />
                <rect
                  x="105.967"
                  y="92.9"
                  width="53.5345"
                  height="15.0995"
                  rx="7.54973"
                  stroke="#D4D4D4"
                  stroke-width="1.37268"
                />
                <rect
                  x="167.051"
                  y="92.2136"
                  width="54.9071"
                  height="16.4721"
                  rx="8.23607"
                  fill="#D4D4D4"
                />
              </svg>
            </div>

            <div class="mt-2">
              <p class="pageSelectionTitle mb-2"  :style="endPageSelection == 'thankYou' ? 'color:#4d1b7e !important;' : null">Thank You Page</p>
              <p class="pageSelectionDescription" >Use this option if you want to display a thank-you page after users complete the form, without any product recommendations.</p>
            </div>
          </div>
        </div>
        <div class="col-6 ">
          <div
          @click="handleEndPageSelection('productMatch')"
            class="w-100 pageSelectionContainer cursor-pointer"
            :class="endPageSelection == 'productMatch' ? 'selected' : null"
          >
            <div
              class="w-100  d-flex align-items-center justify-content-center"
            >
              <svg
                width="327"
                height="145"
                viewBox="0 0 327 145"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.607483"
                  y="0.116577"
                  width="325.458"
                  height="144.648"
                  rx="5.16599"
                  fill="#F9F9F9"
                />
                <rect
                  x="56.142"
                  y="33.6956"
                  width="64.2599"
                  height="45.36"
                  rx="4.12363"
                  fill="#D3D3D3"
                  fill-opacity="0.5"
                />
                <path
                  d="M92.052 52.5955H93.1479C94.1309 52.5955 94.9498 53.3489 95.0314 54.3285L95.6614 61.8885C95.7532 62.9904 94.8837 63.9355 93.7779 63.9355H82.766C81.6603 63.9355 80.7907 62.9904 80.8826 61.8885L81.5126 54.3285C81.5942 53.3489 82.4131 52.5955 83.396 52.5955H84.492M92.052 52.5955H84.492M92.052 52.5955L92.052 51.6505C92.052 50.648 91.6537 49.6865 90.9448 48.9776C90.236 48.2687 89.2745 47.8705 88.272 47.8705C87.2695 47.8705 86.308 48.2687 85.5991 48.9776C84.8902 49.6865 84.492 50.648 84.492 51.6505L84.492 52.5955M92.052 52.5955L92.052 56.3755M84.492 52.5955L84.492 56.3755"
                  stroke="white"
                  stroke-width="1.4175"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="56.6145"
                  y="86.6155"
                  width="64.2599"
                  height="7.55999"
                  rx="1.89"
                  fill="#D4D4D4"
                  fill-opacity="0.5"
                />
                <rect
                  x="56.6144"
                  y="99.8456"
                  width="64.2599"
                  height="3.78"
                  rx="1.89"
                  fill="#D4D4D4"
                  fill-opacity="0.3"
                />
                <rect
                  x="56.6144"
                  y="107.405"
                  width="44.4149"
                  height="3.78"
                  rx="1.89"
                  fill="#D4D4D4"
                  fill-opacity="0.3"
                />
                <rect
                  x="131.269"
                  y="33.6956"
                  width="64.2599"
                  height="45.36"
                  rx="4.12363"
                  fill="#D3D3D3"
                  fill-opacity="0.5"
                />
                <path
                  d="M167.652 52.5955H168.748C169.731 52.5955 170.55 53.3489 170.631 54.3285L171.261 61.8885C171.353 62.9904 170.484 63.9355 169.378 63.9355H158.366C157.26 63.9355 156.391 62.9904 156.482 61.8885L157.112 54.3285C157.194 53.3489 158.013 52.5955 158.996 52.5955H160.092M167.652 52.5955H160.092M167.652 52.5955L167.652 51.6505C167.652 50.648 167.254 49.6865 166.545 48.9776C165.836 48.2687 164.874 47.8705 163.872 47.8705C162.869 47.8705 161.908 48.2687 161.199 48.9776C160.49 49.6865 160.092 50.648 160.092 51.6505L160.092 52.5955M167.652 52.5955L167.652 56.3755M160.092 52.5955L160.092 56.3755"
                  stroke="white"
                  stroke-width="1.4175"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="131.742"
                  y="86.6155"
                  width="64.2599"
                  height="7.55999"
                  rx="1.89"
                  fill="#D4D4D4"
                  fill-opacity="0.5"
                />
                <rect
                  x="131.742"
                  y="99.8456"
                  width="64.2599"
                  height="3.78"
                  rx="1.89"
                  fill="#D4D4D4"
                  fill-opacity="0.3"
                />
                <rect
                  x="131.742"
                  y="107.405"
                  width="44.4149"
                  height="3.78"
                  rx="1.89"
                  fill="#D4D4D4"
                  fill-opacity="0.3"
                />
                <rect
                  x="206.397"
                  y="33.6956"
                  width="64.2599"
                  height="45.36"
                  rx="4.12363"
                  fill="#D3D3D3"
                  fill-opacity="0.5"
                />
                <path
                  d="M242.307 52.5955H243.403C244.386 52.5955 245.205 53.3489 245.286 54.3285L245.916 61.8885C246.008 62.9904 245.139 63.9355 244.033 63.9355H233.021C231.915 63.9355 231.046 62.9904 231.137 61.8885L231.767 54.3285C231.849 53.3489 232.668 52.5955 233.651 52.5955H234.747M242.307 52.5955H234.747M242.307 52.5955L242.307 51.6505C242.307 50.648 241.909 49.6865 241.2 48.9776C240.491 48.2687 239.529 47.8705 238.527 47.8705C237.524 47.8705 236.563 48.2687 235.854 48.9776C235.145 49.6865 234.747 50.648 234.747 51.6505L234.747 52.5955M242.307 52.5955L242.307 56.3755M234.747 52.5955L234.747 56.3755"
                  stroke="white"
                  stroke-width="1.4175"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="206.869"
                  y="86.6155"
                  width="64.2599"
                  height="7.55999"
                  rx="1.89"
                  fill="#D4D4D4"
                  fill-opacity="0.5"
                />
                <rect
                  x="206.869"
                  y="99.8456"
                  width="64.2599"
                  height="3.78"
                  rx="1.89"
                  fill="#D4D4D4"
                  fill-opacity="0.3"
                />
                <rect
                  x="206.869"
                  y="107.405"
                  width="44.4149"
                  height="3.78"
                  rx="1.89"
                  fill="#D4D4D4"
                  fill-opacity="0.3"
                />
              </svg>
            </div>

            <div class="mt-2">
              <p class="pageSelectionTitle mb-2"  :style="endPageSelection == 'productMatch' ? 'color:#4d1b7e !important;' : null">AI-Powered Product Matching</p>
              <p class="pageSelectionDescription" >Let our AI automatically match products from your store with quiz answers, providing personalized recommendations to your users.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Selection End -->

    <!-- Products Page Start -->
    <div class="d-flex flex-column" v-else>
      <div class="d-flex justify-content-between align-items-start">
        <div class="quizell-ai-title mb-1">
          Which products you would like to match?

          <p
            style="
              color: #73738d;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              letter-spacing: 0.56px;
            "
          >
            Select products which AI will match depending on the answers the
            customer gives in the quiz.
          </p>
        </div>
        <div
          class="color:#73738D;"
          v-if="QuizPayload.selectProductIds.length > 0"
        >
          Selected Products:
          <span class="ml-2 font-weight-bold"
            >{{ QuizPayload.selectProductIds.length }}/250</span
          >
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-6">
          <FilterBox v-model="productFilter.title"></FilterBox>
        </div>
        <div class="col-6">
          <div class="d-flex justify-content-end" v-if="loadingProducts">
            <div class="d-flex align-items-center mx-2">
              <b-spinner
                small
                label="Small Spinner"
                style="color: #4d1b7e"
              ></b-spinner
              ><span class="ml-2">Loading Products...</span>
            </div>
          </div>

          <div v-else class="d-flex justify-content-end align-items-center">
            <div
              class="d-flex align-items-center"
              style="
                color: #73738d;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
              "
            >
              <b-form-checkbox
                v-model="selectAllProducts"
                @change="triggerSelectAllProducts()"
                class="checkbox"
              ></b-form-checkbox>
              Select all Products
            </div>
            <div
              @click="resetFilter()"
              class="d-flex align-items-center ml-3"
              style="cursor: pointer; color: #73738d; font-size: 14px"
            >
              <span class="mr-2"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 3C10.6131 3 8.32387 3.94821 6.63604 5.63604C4.94821 7.32387 4 9.61305 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 10.1435 6.7375 8.36301 8.05025 7.05025C9.36301 5.7375 11.1435 5 13 5C14.8565 5 16.637 5.7375 17.9497 7.05025C19.2625 8.36301 20 10.1435 20 12C20 13.8565 19.2625 15.637 17.9497 16.9497C16.637 18.2625 14.8565 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 20 10.5 21 13 21C15.3869 21 17.6761 20.0518 19.364 18.364C21.0518 16.6761 22 14.3869 22 12C22 9.61305 21.0518 7.32387 19.364 5.63604C17.6761 3.94821 15.3869 3 13 3Z"
                    fill="#73738D"
                  />
                </svg>
              </span>
              Refresh Products
            </div>
          </div>
        </div>
        <!--  -->
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <div class="d-flex gap"></div>
          </div>
        </div>
      </div>
      <div class="row mt-1" v-if="isProductLimit">
        <div class="col-12">
          <i class="text-danger" style="font-size: 12px"
            >You can select only 250 products</i
          >
        </div>
      </div>
      <div
        class="mt-3 row"
        style="height: 52vh; overflow: auto"
        v-if="isFirstTimeTableLoad"
      >
        <div class="col-2 col-md-4 my-2" v-for="index in 6" :key="index">
          <div
            class="card"
            style="border-radius: 12px; border-color: rgba(24, 25, 28, 0.08)"
          >
            <div class="card-body" style="padding: 16px">
              <div
                class="integrationImgae bg-preload"
                style="height: 100px; width: 100%; border-radius: 14px"
              >
                <div class="into-preload"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" v-else>
        <div
          class="row"
          v-if="Products.length > 0"
          style="height: 55vh; overflow: auto"
          ref="productContainer"
          @scroll="handleScroll"
        >
          <div
            class="col-2 my-2 col-md-4"
            v-for="(product, index) in Products"
            :key="product.id"
          >
            <label
              class="m-0 w-100"
              :style="{
                'pointer-events':
                  isProductLimit &&
                  !QuizPayload.selectProductIds.includes(product.id)
                    ? 'none'
                    : '',
              }"
              :for="`product-${index + 1}`"
            >
              <div
                class="card cursor-pointer"
                :style="
                  QuizPayload.selectProductIds.includes(product.id)
                    ? 'border-color: #4d1b7e;'
                    : 'border-color: rgba(24, 25, 28, 0.08);'
                "
                style="border-radius: 12px"
              >
                <div class="card-body" style="padding: 16px">
                  <div class="d-flex" style="gap: 10px">
                    <div class="">
                      <lazy-image
                        :src="product.image"
                        style="width: 70px; height: 70px; border-radius: 8px"
                      ></lazy-image>
                    </div>
                    <div class="d-flex flex-grow-1">
                      <div class="" style="flex: 0.9">
                        <div class="ai-product-title">
                          {{ product.title }}
                        </div>
                        <div
                          class="d-flex"
                          style="gap: 10px"
                          v-if="product.price"
                        >
                          <div class="ai-price">$ {{ product.price }}</div>
                          <div
                            class="ai-old-price"
                            v-show="product.compare_at_price"
                          >
                            $ {{ product.compare_at_price }}
                          </div>
                        </div>
                      </div>
                      <div class="" style="flex: 0.1">
                        <b-form-checkbox
                          :value="product.id"
                          v-model="QuizPayload.selectProductIds"
                          :id="`product-${index + 1}`"
                          class="checkbox"
                        ></b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div
          v-else
          class="d-flex justify-content-center align-items-center flex-column w-100"
          style="
            height: 52vh;
            border-radius: var(16px);
            border: 1px solid #ededed;
            background: rgba(179, 175, 182, 0.08);
          "
        >
          <div class="d-flex flex-column align-items-center">
            <p
              class="d-flex justify-content-center align-items-center"
              style="
                border-radius: 50%;
                width: 50px;
                height: 50px;
                background: #eaddf6;
              "
            >
              <svg
                style="z-index: 10000"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.99996 13C10.4294 13.5741 10.9773 14.0491 11.6065 14.3929C12.2357 14.7367 12.9315 14.9411 13.6466 14.9923C14.3617 15.0435 15.0795 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4507 9.59695 21.9547 8.33394 21.9433 7.02296C21.9319 5.71198 21.4061 4.45791 20.479 3.53087C19.552 2.60383 18.2979 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997M14 11C13.5705 10.4258 13.0226 9.95078 12.3934 9.60703C11.7642 9.26327 11.0684 9.05885 10.3533 9.00763C9.63816 8.95641 8.92037 9.0596 8.24861 9.31018C7.57685 9.56077 6.96684 9.9529 6.45996 10.46L3.45996 13.46C2.54917 14.403 2.04519 15.666 2.05659 16.977C2.06798 18.288 2.59382 19.542 3.52086 20.4691C4.4479 21.3961 5.70197 21.9219 7.01295 21.9333C8.32393 21.9447 9.58694 21.4408 10.53 20.53L12.24 18.82"
                  stroke="#4D1B7E"
                  stroke-width="2.004"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </p>

            <p
              style="
                color: #101828;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
              "
            >
              You don't have any products
            </p>
            <p
              style="
                color: #667085;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
              "
            >
              You can upload your products in a way convenient for you
            </p>

            <div class="d-flex align-items-center justify-content-center">
              <Button
                class="mr-3"
                style="
                  background: transparent;
                  font-weight: 600;
                  border: 1px solid #4d1b7e;
                  color: #4d1b7e;
                "
                @click="openPlatformProducts"
                >Import CSV File</Button
              >
              <Button style="font-weight: 600" @click="openPlatformProducts"
                >Add Manually</Button
              >
            </div>
          </div>
          <div
            class="mt-3"
            v-if="
              getUserType == 'shopify' ||
              getUserType == 'bc' ||
              getUserType == 'wix'
            "
          >
            <div class="mb-3 d-flex align-items-center justify-content-between">
              <div style="height: 1px; background: #eaecf0; width: 100%"></div>
              <div class="mx-5">
                <span
                  style="
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    color: #73738d;
                  "
                  >OR</span
                >
              </div>
              <div style="height: 1px; background: #eaecf0; width: 100%"></div>
            </div>

            <ImportProductsPlatformButtons
              :getUserType="getUserType"
              :openPlatformProducts="openPlatformProducts"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Product Page End -->
    <div class="row box-with-shadow mt-3">
      <div
        class="col-12 d-flex"
        :class="
          endPageView !== 'pageSelection'
            ? 'justify-content-between'
            : 'justify-content-end'
        "
      >
        <div
          v-if="endPageView !== 'pageSelection'"
          class="d-flex cursor-pointer align-items-center"
          @click="endPageView = 'pageSelection'"
          style="
            color: rgb(115, 115, 141);
            font-size: 14px;
            cursor: pointer;
            font-weight: 500;
          "
        >
          <span class="mr-2"
            ><svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6667 9.16669V10.8334H6.66667L11.25 15.4167L10.0667 16.6L3.46667 10L10.0667 3.40002L11.25 4.58336L6.66667 9.16669H16.6667Z"
                fill="#73738D"
              ></path></svg
          ></span>
          Change preference
        </div>
        <div class="d-flex gap">
          <Button
          v-if="endPageView !== 'pageSelection'"
            @click="$emit('click')"
            class="mr-2"
            style="background: #b3afb6; min-width: 20px"
            >I don't want to match products
          </Button>
          <Button @click="handleFlowBtnClick"
            > {{ endPageSelection == 'thankYou' ? 'Generate Quiz' : endPageView == 'aiProductMatch' ? 'Generate Quiz' : 'Next' }}
            <span class="mr-1"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M3.33334 9.16669V10.8334H13.3333L8.75001 15.4167L9.93334 16.6L16.5333 10L9.93334 3.40002L8.75001 4.58336L13.3333 9.16669H3.33334Z"
                  fill="white"
                /></svg
            ></span>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Button from "../../Layout/Button.vue";
//import ShopifyPagination from "../../Layout/ShopifyPagination.vue";
import FilterBox from "../../Layout/FilterBox.vue";
//import FilterSelect from '../../Layout/FilterSelect.vue';
import { debounce } from "lodash";

import { mapGetters } from "vuex";
export default {
  components: {
    ImportProductsPlatformButtons: () =>
      import(
        /* webpackChunkName: "ImportProductsPlatformButtons" */ "./ImportProductsPlatformButtons.vue"
      ),
    Button,
    // ShopifyPagination,
    FilterBox,
    // FilterSelect
  },
  props: {
    QuizPayload: Object,
  },
  computed: {
    ...mapGetters(["getNavbarData"]),
    getUserType() {
      return this.getNavbarData.userType;
    },
    isProductLimit() {
      return this.QuizPayload.selectProductIds.length >= 250;
    },
    hasNextPage() {
      return this.currentPage <= this.last_page;
    },
    hasPreviousPage() {
      // True if current page is greater than 1
      return this.currentPage > 1;
    },
    selectedTag() {
      const index = this.allTags.findIndex(
        (tag) => tag.title == this.productFilter.tag
      );
      let selectTag = null;
      if (index > -1) {
        selectTag = this.allTags[index];
      }
      return selectTag;
    },
  },
  watch: {
    "productFilter.title"(newValue) {
      if (newValue) {
        // Store current list and pagination when filter is applied
        if (!this.originalProducts.length) {
          this.originalProducts = [...this.Products];
          this.originalPage = this.currentPage;
          this.originalTotalRows = this.totalRows;
          this.originalLastPage = this.last_page;
        }
        this.currentPage = 1;
        this.loadSavedProducts("resetProducts");
      } else {
        // Revert to original list and pagination values if filter is cleared
        this.Products = [...this.originalProducts];
        this.currentPage = this.originalPage;
        this.totalRows = this.originalTotalRows;
        this.last_page = this.originalLastPage;

        // Clear stored values once reverted
        this.originalProducts = [];
        this.originalPage = 1;
        this.originalTotalRows = 0;
        this.originalLastPage = 1;
      }
    },
  },
  data() {
    return {
      allCollections: [],
      text: "",
      tagInput: "",
      loadingProducts: false,
      currentPage: 1,
      last_page: 1,
      totalRows: 10,
      perPage: 21,
      Products: [],
      allTags: [],
      selectAllProducts: false,
      productFilter: { title: "", tag: "", collection_ids: [], status: 1 },
      isFirstTimeTableLoad: true,

      endPageSelection: "productMatch",
      endPageView: "pageSelection",

      originalProducts: [], // Store the original list of products
      originalPage: 1, // Store the original page
      originalTotalRows: 0, // Store the original total rows
      originalLastPage: 1, // Store the original last page
    };
  },
  methods: {
    handleEndPageSelection(value){
      this.QuizPayload.endPage = value
      this.endPageSelection = value
    },
    handleFlowBtnClick() {

      if(this.endPageView == 'aiProductMatch') {
        this.$emit("click");
      } else{
        if (this.endPageSelection == "productMatch") {
        this.endPageView = "aiProductMatch";
      } else {
        this.$emit("click");
      }
      }


     
    },

    triggerSelectAllProducts() {
      if (this.selectAllProducts) {
        const productIds = this.Products.slice(0, 250).map((item) => item.id);
        this.QuizPayload.selectProductIds = productIds;
      } else {
        this.QuizPayload.selectProductIds = [];
      }
    },
    openPlatformProducts() {
      const routeData = this.$router.resolve({ name: "Product" });
      window.open(routeData.href, "_blank");
    },
    resetFilter() {
      (this.productFilter = {
        title: "",
        tag: "",
        collection_ids: [],
        status: 1,
      }),
        (this.currentPage = 1);
      this.loadSavedProducts("resetProducts");
    },
    handleProductSearchDebounced: debounce(function () {
      this.currentPage = 1;
      this.loadSavedProducts();
    }, 500),
    updateCollections(e) {
      console.log(e);
      this.productFilter.collection_ids = e.map((col) => col.id.toString());
      this.currentPage = 1;
      this.loadSavedProducts();
    },
    updateTag(e) {
      this.productFilter.tag = e.title;
      this.currentPage = 1;
      this.loadSavedProducts();
    },
    async filterHandle() {
      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "get",
        resource: "products",
        method: "filter",
      });
      if (response && response.status) {
        this.allTags = response.data.tags;
        this.allCollections = response.data.collection;
      }
    },
    async loadSavedProducts(action = "") {
      if (
        (this.loadingProducts || !this.hasNextPage) &&
        !this.isFirstTimeTableLoad
      )
        return;

      this.loadingProducts = true;
      try {
        const response = await axios.post("/loadSavedProducts", {
          page: this.currentPage,
          per_page: this.perPage,
          ...this.productFilter,
        });
        if (response.status === 200) {
          if (action && action == "resetProducts") {
            this.Products = response.data.data.data;
          } else {
            this.Products = [...this.Products, ...response.data.data.data];
          }

          this.perPage = response.data.data.per_page;
          this.totalRows = response.data.data.total;
          this.currentPage += 1;
          this.last_page = response.data.data.last_page;
        }
      } catch (error) {
        this.$toasted.show("Error occurred", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.loadingProducts = false;
        this.selectAllProducts = false;
        this.isFirstTimeTableLoad = false;
      }
    },
    handleScroll() {
      const container = this.$refs.productContainer;
      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - 10
      ) {
        this.loadSavedProducts();
      }
    },
  },
  async mounted() {
    // await this.filterHandle();
    this.QuizPayload.selectProductIds = [];
    this.loadSavedProducts();
  },
};
</script>
<style scoped>
.ai-product-title {
  color: #1f1f28;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ai-price {
  color: var(--primary-color);

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ai-old-price {
  color: #73738d;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: line-through;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--primary-color);
  background-color: var(--primary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--primary-color);
}

.quizell-ai-title {
  color: #1f1f28;

  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: 0.88;
}

.pageSelectionContainer {
  min-height: 250px;
  padding: 10.332px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10.332px;
  border-radius: 7.749px;
  border: 0.646px solid rgba(31, 31, 40, 0.15);
  background: #fff;
}
.pageSelectionContainer.selected {
  border: 0.646px solid #4d1b7e;
}

.pageSelectionTitle{
  color: #1F1F28;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 100%;
letter-spacing: 0.413px;
}
.pageSelectionDescription{
  color: #73738D;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 140%;
letter-spacing: 0.31px;
}
</style>
